import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BaiduMap from 'vue-baidu-map'
Vue.config.productionTip = false
// import 'element-ui/lib/theme-chalk/index.css'

import { Button, Popover, Menu, MenuItem, Submenu, Carousel, CarouselItem,Message,Input,Toast,Dropdown,DropdownMenu,DropdownItem,Icon,Select,Option,Upload,Radio,Table,TableColumn,DatePicker,Dialog,MessageBox,Loading,Checkbox,InputNumber,Form,FormItem,Drawer} from 'element-ui';
import './element-variables.scss'
Vue.use(BaiduMap, {ak:'2Zvw46SfEejaTIckyE8SwSWxcUkALsxi'});
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Popover);
Vue.use(Button);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Icon);
Vue.use(Select);
Vue.use(Option);
Vue.use(Upload);
Vue.use(Radio);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(Checkbox);
Vue.use(InputNumber);
Vue.use(FormItem);
Vue.use(Form);
Vue.use(Drawer);
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 1000 };
Vue.prototype.$message = Message;
Vue.prototype.$toast = Toast;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$loading = Loading.service;

import { Tab, Tabs,NavBar} from 'vant';
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NavBar);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
