const webRouter = [//pc
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/themes/home'),//首页
        children:[
            //page 默认页
            {
                path: '/',
                name: 'homePage',
                component:() => import('@/views/themes/page/homePage.vue'), // 默认页
                meta: {
                    keepAlive: true,
                    isBack: false
                },
            },
            {
                path: '/index2',
                name: 'homePage2',
                component:() => import('@/views/themes/page/homePage2.vue'), // 默认页
                meta: {
                    keepAlive: true,
                    isBack: false
                },
            },
            {
                path: '/index3',
                name: 'homePage3',
                component:() => import('@/views/themes/page/homePage3.vue'), // 默认页
                meta: {
                    keepAlive: true,
                    isBack: false
                },
            },
            {
                path: '/search',
                name: 'search',
                component:() => import('@/views/themes/page/search.vue'), // 默认页
                meta: {
                    keepAlive: true,
                    isBack: false
                },
            },
            {
                path: '/manufacturer',
                name: 'manufacturer',
                component:() => import('@/views/themes/page/manufacturer.vue'), // 厂商
            },
            {
                path: '/ma_particulars',
                name: 'ma_particulars',
                component:() => import('@/views/themes/page/ma_particulars.vue'), // 厂商详情
            },
            {
                path: '/about',
                name: 'about',
                component:() => import('@/views/themes/page/about.vue'), // 关于我们
            },
            {
                path: '/aboutus',
                name: 'aboutus',
                component:() => import('@/views/themes/page/aboutus.vue'), // 关于我们
            },
            {
                path: '/IPbqf',
                name: 'IPbqf',
                component:() => import('@/views/themes/page/IPbqf.vue'), // IP版权方
            },
            {
                path: '/entitysj',
                name: 'entitysj',
                component:() => import('@/views/themes/page/entitysj.vue'), // 实体商家
            },
            {
                path: '/pa_composer',
                name: 'pa_composer',
                component:() => import('@/views/themes/page/pa_composer.vue'), // 独立创作人
            },
            {
                path: '/particulars',
                name: 'particulars',
                component:() => import('@/views/themes/page/particulars.vue'), // 详情
            },
            //login 登录 注册
            {
                path: '/login',
                name: 'login',
                component:() => import('@/views/themes/login/login.vue'), // 登录
            },
            {
                path: '/register',
                name: 'register',
                component:() => import('@/views/themes/login/register.vue'), // 注册
            },
            {
                path: '/forgetPass',
                name: 'forgetPass',
                component:() => import('@/views/themes/login/forgetPass.vue'), // 密码重置
            },
            //Incubationproject 孵化项目
            {
                path: '/IncubationProject',
                name: 'IncubationProject',
                component:() => import('@/views/themes/Incubationproject/IncubationProject.vue'), // 孵化项目
                meta: {
                    keepAlive: true,
                    isBack: false
                },
            },
            {
                path: '/In_details',
                name: 'In_details',
                component:() => import('@/views/themes/Incubationproject/In_details.vue'), // 孵化项目详情
            },
            //live 发布/发现
            {
                path: '/live',
                name: 'live',
                component:() => import('@/views/themes/live/live.vue'), // 发布/发现
                meta: {
                    keepAlive: true,
                    isBack: false
                },
            },
            //info 版权交易
            {
                path: '/info',
                name: 'info',
                component:() => import('@/views/themes/info/info.vue'), // 版权交易
                meta: {
                    keepAlive: true,
                    isBack: false
                },
            },
            {
                path: '/info_detail',
                name: 'info_detail',
                component:() => import('@/views/themes/info/detail.vue'), // 版权交易详情
            },
            //info 版权交易
            {
                path: '/news',
                name: 'news',
                component:() => import('@/views/themes/news/news.vue'), // 版权交易
                meta: {
                    keepAlive: false,
                    isBack: false
                },
            },
            {
                path: '/news_detail',
                name: 'news_detail',
                component:() => import('@/views/themes/news/detail.vue'), // 版权交易详情
            },
            {
                path: '/class_detail',
                name: 'class_detail',
                component:() => import('@/views/themes/news/class.vue'), // 版权交易详情
            },
            //info 版权交易
            {
                path: '/belief',
                name: 'belief',
                component:() => import('@/views/themes/belief/belief.vue'), // 版权交易
                meta: {
                    keepAlive: false,
                    isBack: false
                },
            },
            {
                path: '/belief_detail',
                name: 'belief_detail',
                component:() => import('@/views/themes/belief/detail.vue'), // 版权交易详情
            },
            //info 版权交易
            {
                path: '/joinus',
                name: 'joinus',
                component:() => import('@/views/themes/joinus/joinus.vue'), // 版权交易
                meta: {
                    keepAlive: false,
                    isBack: false
                },
            },
            {
                path: '/joinus_detail',
                name: 'joinus_detail',
                component:() => import('@/views/themes/joinus/detail.vue'), // 版权交易详情
            },
            //info 版权交易
            {
                path: '/everyday',
                name: 'everyday',
                component:() => import('@/views/themes/everyday/everyday.vue'), // 版权交易
                meta: {
                    keepAlive: false,
                    isBack: false
                },
            },
            {
                path: '/matins',
                name: 'matins',
                component:() => import('@/views/themes/everyday/matins.vue'), // 版权交易
                meta: {
                    keepAlive: false,
                    isBack: false
                },
            },
            {
                path: '/lectern',
                name: 'lectern',
                component:() => import('@/views/themes/everyday/lectern.vue'), // 版权交易
                meta: {
                    keepAlive: false,
                    isBack: false
                },
            },
            {
                path: '/offices',
                name: 'offices',
                component:() => import('@/views/themes/everyday/offices.vue'), // 版权交易
                meta: {
                    keepAlive: false,
                    isBack: false
                },
            },
            {
                path: '/everyday_detail',
                name: 'everyday_detail',
                component:() => import('@/views/themes/everyday/detail.vue'), // 版权交易详情
            },
            //info 版权交易
            {
                path: '/music',
                name: 'music',
                component:() => import('@/views/themes/music/music.vue'), // 版权交易
                meta: {
                    keepAlive: false,
                    isBack: false
                },
            },
            {
                path: '/music_detail',
                name: 'music_detail',
                component:() => import('@/views/themes/music/detail.vue'), // 版权交易详情
            },
            //info 版权交易
            {
                path: '/video',
                name: 'video',
                component:() => import('@/views/themes/video/video.vue'), // 版权交易
                meta: {
                    keepAlive: false,
                    isBack: false
                },
            },
            {
                path: '/video_detail',
                name: 'video_detail',
                component:() => import('@/views/themes/video/detail.vue'), // 版权交易详情
            },
            //CopyrightRequirements 版权需求
            {
                path: '/CopyrightRequirements',
                name: 'CopyrightRequirements',
                component:() => import('@/views/themes/CopyrightRequirements/CopyrightRequirements.vue'), // 版权需求
                meta: {
                    keepAlive: true,
                    isBack: false
                },
            },
            {
                path: '/Co_Details',
                name: 'Co_Details',
                component:() => import('@/views/themes/CopyrightRequirements/Co_Details.vue'), // 版权需求详情
            },
            //AuthorizeTheCase 授权案例
            {
                path: '/AuthorizeTheCase',
                name: 'AuthorizeTheCase',
                component:() => import('@/views/themes/AuthorizeTheCase/AuthorizeTheCase.vue'), // 授权案例
                meta: {
                    keepAlive: true,
                    isBack: false
                },
            },
            {
                path: '/Au_case',
                name: 'Au_case',
                component:() => import('@/views/themes/AuthorizeTheCase/Au_case.vue'), // 授权案例详情
            },
            //BusinessEntity 个人中心 实体商家
            {
                path: '/BusinessEntity',
                name: 'BusinessEntity',
                component:() => import('@/views/themes/BusinessEntity/BusinessEntity.vue'), // 实体商家
            },
            //IPcopyrigh 个人中心 IP版权方
            {
                path: '/IPcopyrigh',
                name: 'IPcopyrigh',
                component:() => import('@/views/themes/IPcopyrigh/IPcopyrigh.vue'), // IP版权方
            },
            //IPcopyrigh 个人中心 独立创作人
            {
                path: '/Composer',
                name: 'Composer',
                component:() => import('@/views/themes/Composer/Composer.vue'), // 独立创作人
            },
        ],

    },
]

export default webRouter
